import lang from "element-ui/lib/locale/lang/es";

const requirements = {
  1: "Tener entre 18 y 75 Años de edad para trabajadores activos. Y jubilados hasta 80 años.",
  2: "Cobrar tu Salario con BNF.",
  3: "Al menos 6 meses de antigüedad laboral para Empleados Contratados.",
  4: "No tener mora con tus deudas en BNF.",
};

const keys = {
  No_autorized: {
    title: "Actualiza por favor",
    message:
      "Hemos actualizado nuestro calculador, por favor refresca el navegador.",
  },
  date_format: "DD/MM/YYYY",
  el: lang.el,
  1: "Si",
  0: "No",
  dont_know: "No sé",
  home: "Inicio",
  Planta: "Piso",
  errors: {
    _404: {
      header: "Página no encontrada",
      title: "¡OOPS!",
      error: "Error 404 - Página no encontrada",
      message:
        "La página que está buscando no existe o está temporalmente inhabilitada",
    },
    _401: {
      header: "No autorizado",
      title: "¡No autorizado!",
      message: "Su empresa no está autorizada",
    },
  },
  credit_motive: {
    liquidez: "Liquidez",
    reparacion_vehiulo: "Reparación Vehículo",
    reforma_hogar: "Reforma Hogar",
    pago_factura: "Pago Factura",
    celebracion: "Celebración",
    viaje: "Viaje",
  },
  income_source: {
    Nomina: "Nomina",
    Subsidio: "Subsidio",
    Pension: "Pension",
    Alquiler: "Alquiler",
  },
  vnz_universities: {
    IESA: "Instituto de Estudios Sup. de Adm. (IESA)",
    ISUM: "Instituto Superior Universitario de Mercadotecnia (ISUM)",
    IUNP: "Instituto Universitario de Nuevas Profesiones (IUNP)",
    IUTIRLA: "Instituto Universitario de Tec. Ind. R. L. Arismendi (IUTIRLA)",
    PSM: "Instituto Universitario Politécnico Santiago Mariño (PSM)",
    IUTAV: "Instituto Universitario Tec. Américo Vespucio (IUTAV)",
    UAH: "Universidad Alejandro Humboldt",
    UAM: "Universidad Arturo Michelena (UAM)",
    UBV: "Universidad Bolivariana de Venezuela (UBV)",
    UCAB: "Universidad Católica Andrés Bello (UCAB)",
    UCSAR: "Universidad Católica Santa Rosa (UCSAR)",
    UCV: "Universidad Central de Venezuela (UCV)",
    ULA: "Universidad de Los Andes (ULA)",
    UJAP: "Universidad José Antonio Páez (UJAP)",
    UJMV: "Universidad José María Vargas (UJMV)",
    UNIMET: "Universidad Metropolitana (UNIMET)",
    UMA: "Universidad Monteávila (UMA)",
    UNEXPO: "Universidad N. E. Politécnica A. J. de Sucre (UNEXPO)",
    UNEFA: "Universidad Nac. E. Politécnica de la Fza. Armada Bol. (UNEFA)",
    UNA: "Universidad Nacional Abierta (UNA)",
    UNESR: "Universidad Nacional Experimental Simón Rodríguez (UNESR)",
    UNIPAP: "Universidad Panamericana del Puerto (UNIPAP)",
    UPEL: "Universidad Pedagógica Experimental Libertador (UPEL)",
    UPV: "Universidad Politécnica de Valencia (UPV)",
    USM: "Universidad Santa María (USM)",
    USB: "Universidad Simón Bolívar (USB)",
    LUV: "Universidad Virtual Latinoamericana (LUV)",
  },
  footer_text: "",
  connexion_error: "Error de conexión, por favor reintente más tarde.",
  version: "Versión",
  i_waned: "La quiero",
  just_few_questions: "Sólo un par de preguntas más",
  Cant_payments_plans: "Plazos",
  cant_product: "Productos",
  contact_us: "Contáctanos",
  email: "Correo electrónico",
  country: "País",
  continue: "Continuar",
  go_back: "Atrás",
  accept: "Aceptar",
  close: "Cerrar",
  calc_credito: "Calcula tu crédito",
  rejected_loan: "Solicitud rechazada",
  not_available: "Producto no disponible",
  need_guarantee: "Necesitas un garante",
  password_restrictions:
    "La contraseña no cumple con las políticas establecidas. Por favor consúltelas en la ayuda",
  password_length: "La contraseña debe tener minimo {min} cracteres",
  congrats: "¡Felicidades!",
  should_accept: "Debe aceptar los términos y condiciones",
  accept_terms_and_conditions: "Aceptar términos y condiciones",
  accept_terms: "Aceptar {name}",
  i_accept_terms_and_conditions: "Acepto los términos y condiciones",
  i_accept_terms: "Acepto {name}",
  had_accept_terms_and_conditions: "He aceptado los términos y condiciones",
  see_terms_and_conditions: "Ver términos y condiciones",
  see_terms: "Ver {name}",
  terms_and_conditions: "Términos y condiciones",
  sorry: "Lo sentimos",
  product_selection: "Seleccione el crédito que mas le convenga",
  rules: {
    incomplete: "Debe completar la información",
    should_accept_terms: "Por favor, debe aceptar {name} .",
    should_validate_bank_data: "Por favor, debe validar sus datos bancarios.",
    document_number_mandatory: "Por favor, ingrese el número de documento.",
    document_company_invalid_format:
      "El documento ingresado no es válido, Formato Ej: 123456789000.",
    document_applicant_invalid_format:
      "El documento ingresado no es válido, Formato Ej: 12345678.",
    field_mandatory: "El campo {field} es requerido, por favor verifíquelo.",
    field_not_equal:
      "El campo  {field} no coincide con {compare_field}, por favor verifíquelo.",
    invalid_field: "El campo  {field} no es válido, por favor verifíquelo.",
    invalid_range: "El campo  {field} debe de ser ",
    invalid_range_higher: "mayor que {field} ",
    invalid_range_and: " y ",
    invalid_range_lower: "menor que {field}.",
    country_code: "Código del país",
    PhoneNumber: "número de teléfono",
    field_number: "El campo debe contener sólo números",
    identity_rule: "El documento de identidad es invalido.",
    phone_rule: "Longitud de teléfono incorrecto.",
    phone_exists: "Numero de teléfono asociado a otro cliente.",
    name_rule: "El nombre no coincide con los datos proveidos por el banco. ",
    name_required: "El campo es requerido, por favor verifíquelo.",
    lastname_rule:
      "El apellido no coincide con los datos proveidos por el banco.",
    lastname_required: "El campo es requerido, por favor verifíquelo.",
    validate_monto: "El monto a prestar es mayor al limite establecido, por favor verifíquelo.",
    empresa_oferente_required: "El campo es requerido, por favor verifíquelo.",
    empresa_oferente_invalido: "Debe seleccionar una Empresa Oferente válida .",
  },
  forms_flujo: {
    accept_terms_and_conditions: "Por favor acepte los términos y condiciones",
    check_form: {
      is_in_clearing: "¿Tiene incumplimientos en Clearing?",
      comprobante_domicilio: "¿Tiene comprobante de domicilio?",
      comprobante_ingresos: "¿Tiene comprobante de ingresos/ recibo de sueldo?",
    },
    PzSimulator: {
      how_many_cash: "¿Cuánto necesitas?",
      how_many_fee: "¿En cuántas cuotas?",
      how_many_discount: "¿Cuánto descuentas?",
      limit_date: "Fecha de vencimiento",
      first_limit_date: "Fecha del primer vencimiento",
      annual_sells: "¿Tus ventas anuales?",
      emission_date: "Fecha de emisión",
      recived: "Recíbes ",
      tasas: " con tasas desde el ",
      in: " en ",
      from: " desde ",
      select_date: "Seleccione fecha",
    },
    SimulatorVehiculo: {
      valor_vehiculo: "Valor del Vehículo",
      valor_vehiculoGs: "Valor del Vehículo en Gs",
      valor_seguro: "Valor del Seguro",
      valor_seguroGs: "Valor del Seguro en Gs",
      valor_prestamo: "Valor Préstamo a Financiar",
      plazo: "Plazo",
      entrega_inicialGs: "Entrega inicial en Gs",
      entrega_inicial: "Entrega inicial",
      valor_transferencia: "Valor de la Transferencia",
      valor_transferenciaGs: "Valor de la Transferencia en Gs",
      linea_otorgar: "Línea a Otorgar/Rotativa",
    },
    pz_simulators_template: {
      btn_accept: "Solicitar",
      americana: "El importe solicitado será pagado en la última cuota",
      approximate_value:
        "Valor de cuota aproximada depende del perfil asignado al cliente luego de completar el formulario de solicitud",
      note: "Nota: Los cálculos son aproximados",
      info_bnf: {
        1: "Si su solicitud es aprobada, se debitara de su cuenta el costo de Gs. 46.585 (IVA incluido) al momento del desembolso del préstamo",
      },
      warning_bnf:
        "Si cierra la pantalla o su equipo se apaga antes de terminar de cargar su solicitud, deberá comunicarse al 413 9000 o pasar por una de nuestras sucursales.",
    },
      pz_simulators_vehiculo: {
          msg1: "",
          msg3:
              "Si su solicitud es aprobada, se debitara de su cuenta el costo de Gs. 46.585 (IVA incluido) al momento del desembolso del préstamo",
      },
    continuar_doc_form: {
      title: "Ingrese el código de verificación que se le ha enviado.",
      numero_documento: "C.I.",
      codigo_verificacion: "Código de verificación",
    },
    form_documentacion_empresa: {
      title: "Documentación de la empresa.",
      download_template: "Descargar plantilla",
      drag: "Arrastre el archivo aquí o ",
      click: "click para subir",
    },
    form_documentacion_externa: {
      title: "Documentación del Solicitante.",
      download_template: "Descargar plantilla",
      drag: "Arrastre el archivo aquí o ",
      click: "click para subir",
    },
    form_documentacion_persona: {
      title: "Documentación del solicitante (Escaneado o Foto).",
      sub_title: "",
      download_template: "Descargar plantilla",
      drag: "Arrastre el archivo aquí o ",
      click: "click para subir",
      persona_template: "Plantilla",
      name_button: "Continuar",
    },
    form1_empresa: {
      UY: {
        title: "Datos del solicitante.",
        name: "Nombres",
        surename: "Apellidos",
        doc_number: "C.I.",
        state: "Departamento",
        phone: "Teléfono",
        email: "E-mail",
      },
      MX: {
        title: "Datos del solicitante.",
        name: "Nombres",
        surename: "Apellidos",
        doc_number: "C.I.",
        state: "Departamento",
        phone: "Teléfono",
        email: "E-mail",
      },
      SP: {
        title: "Datos personales.",
        name: "Nombres",
        surename: "Apellidos",
        doc_number: "C.I.",
        state: "Provincia",
        phone: "Movil",
        email: "E-mail",
      },
    },
    form1_persona: {
      UY: {
        title: "Algunos datos personales...",
        tipo_doc: "Tipo de documento",
        identity_doc: "Documento de identidad",
        civil_state: "Estado civil",
        fecha_ingreso: "¿Desde cuándo está trabajando?",
        ingreso_mensual: "¿Cuántos son tus ingresos mensuales?",
        telefono: "Celular",
        fecha_nacimiento: "Fecha de nacimiento",
        cuota_maxima: "¿Cuánto es lo máximo que puedes pagar por mes?",
        destino_prestamo: "Destino del crédito",
        jubilado: "¿Jubilado?",
        jubilado_no: "No estoy jubilado",
        jubilado_si: "Estoy jubilado",
        sucursales: "Sucursal a solicitar el prestamo",
        tipo_vehiculo: "Tipo y Marca del Vehículo",
        empresa_oferante: "Empresa Oferente",
      },
      MX: {
        title: "Algunos datos personales...",
        tipo_doc: "CURP",
        identity_doc: "Documento de identidad",
        civil_state: "Estado civil",
        fecha_ingreso: "¿Desde cuándo está trabajando?",
        ingreso_mensual: "¿Cuántos son tus ingresos mensuales?",
        telefono: "Móvil",
        fecha_nacimiento: "Fecha de nacimiento",
        cuota_maxima: "¿Cuánto es lo máximo que puedes pagar por mes?",
        destino_prestamo: "Destino del crédito",
        jubilado: "¿Jubilado?",
        jubilado_no: "No estoy jubilado",
        jubilado_si: "Estoy jubilado",
      },
      SP: {
        title: "Algunos datos personales...",
        identity_doc: "Documento de identidad",
        tipo_doc: "Tipo de documento",
        nombre: "Nombre",
        nacionalidad: "Nacionalidad",
        apellidos: "Apellidos",
        mail: "Correo electrónico",
        civil_state: "Estado civil",
        fecha_ingreso: "¿Desde cuándo está trabajando?",
        ingreso_mensual: "¿Cuántos son tus ingresos mensuales?",
        telefono: "Móvil",
        fecha_nacimiento: "Fecha de nacimiento",
        cuota_maxima: "¿Cuánto es lo máximo que puedes pagar por mes?",
        destino_prestamo: "Motivo del crédito",
        jubilado: "¿Jubilado?",
        jubilado_no: "No estoy jubilado",
        jubilado_si: "Estoy jubilado",
        genero: "Género",
        masculino: "Masculino",
        femenino: "Femenino",
        otro: "Otro",
        entidad_nacimiento: "Lugar de nacimiento",
        tiene_carnet_conducir: "¿Tiene carnet de conducir?",
        studies: "Nivel educacional",
        institucion_de_estudio: "Institución de estudio",
        CIV: "Cédula de identidad venezolana",
        banco_deposito: "Banco",
        cuenta_deposito: "Número de cuenta",
        acepted_before:
          "Ha sido aceptado por otra empresa de crédito anteriormente",
        has_others_credits: "Mantiene créditos con otra empresa crediticia",
        accept_advertising: "Acepto recibir ofertas por correo",
        comprobacion_sc: "Comprobación rápida del estado de tu cuenta",
        comprobacion_unnax: "Comprobación rápida del estado de tu cuenta",
      },
    },
    incentivo_descuentos_default: {
      label_1: "Recibís",
      label_2: " tasa del ",
      label_3:
        "Valor de cuota aproximada depende del perfil asignado al cliente luego de completar el formulario de solicitud",
      label_4: "Nota: Los cálculos son aproximados",
    },
    incentivo_solicitud_default: {
      label_1: "¡Estás a solo dos pasos de acceder a los",
      label_2: " en ",
      label_3: " cuotas de",
      label_4: "!",
    },
    form2_empresa: {
      UY: {
        title: "Datos de la empresa",
        company: "Nombre fantasia",
        company_name: "Razón social",
        doc_number: "RUT",
        activity_start: "Comienzo de actividades",
        activity_type: "Rubro",
        address: "Dirección",
        add_partner: "Agregar socio",
        phone: "Teléfono",
        email: "E-mail",
        partner: {
          name: "Nombres",
          surename: "Apellidos",
          doc_number: "C.I.",
        },
      },
      MX: {
        title: "Datos de la empresa",
        company: "Nombre fantasia",
        company_name: "Razón social",
        doc_number: "RUT",
        activity_start: "Comienzo de actividades",
        activity_type: "Rubro",
        address: "Dirección",
        add_partner: "Agregar socio",
        phone: "Teléfono",
        email: "E-mail",
        partner: {
          name: "Nombres",
          surename: "Apellidos",
          doc_number: "C.I.",
        },
      },
      SP: {
        title: "Datos de la empresa",
        company: "Nombre fantasia",
        company_name: "Razón social",
        doc_number: "RUT",
        activity_start: "Comienzo de actividades",
        activity_type: "Rubro",
        address: "Dirección",
        add_partner: "Agregar socio",
        phone: "Teléfono",
        email: "E-mail",
        partner: {
          name: "Nombres",
          surename: "Apellidos",
          doc_number: "C.I.",
        },
      },
    },
    form2_persona: {
      UY: {
        title: "Ya casi es tuyo",
        property_type: "Tipo de vivienda",
        state: "Departamento",
        phone: "Teléfono fijo",
        employee_type: "Relación laboral",
        address: "Dirección y localidad",
        email: "E-mail",
        studies: "Último estudio cursado",
        company_phone: "Teléfono del trabajo",
        how_find_out: "¿Cómo se enteró de la empresa?",
        mandatory_field: "El campo debe ser completado",
        contact: {
          title: "Persona de contacto",
          sub_title:
            "Persona - familiar o conocido - a quien poder contactar en caso de no poder contactar directo con el solicitante",
          full_name: "Nombre y apellido",
          phone: "Teléfono de contacto",
        },
      },
      MX: {
        title: "Ya casi es tuyo",
        property_type: "Tipo de vivienda",
        state: "Estado",
        phone: "Teléfono fijo",
        level_3: "Municipio",
        level_4: "Colonia",
        postal_code: "Código postal",
        employee_type: "Relación laboral",
        address: "Dirección y localidad",
        email: "E-mail",
        studies: "Último estudio cursado",
        company_phone: "Teléfono del trabajo",
        how_find_out: "¿Cómo se enteró de la empresa?",
        mandatory_field: "El campo debe ser completado",
        contact: {
          title_personal: "Referencias personales",
          personal_full_name: "Nombre y apellido",
          personal_address: "Dirección",
          personal_phone: "Teléfono de contacto",
          personal_parentship: "Parentesco",
          title_familiar: "Referencias familiares",
          familiar_address: "Dirección",
          familiar_phone: "Teléfono de contacto",
          familiar_full_name: "Nombre y apellido",
          familiar_parentship: "Parentesco",
        },
      },
      SP: {
        title: "Ya casi es tuyo",
        property_type: "Tipo de vivienda",
        state: "Provincia",
        phone: "Teléfono de la casa",
        employee_type: "Relación laboral",
        address: "Dirección y localidad",
        email: "E-mail",
        studies: "Último estudio cursado",
        company_phone: "Teléfono del trabajo",
        how_find_out: "¿Cómo se enteró de la empresa?",
        mandatory_field: "El campo debe ser completado",
        contact: {
          title: "Persona de contacto",
          sub_title:
            "Persona - familiar o conocido - a quien poder contactar en caso de no poder contactar directo con el solicitante",
          full_name: "Nombre y apellido",
          phone: "Teléfono de contacto",
        },
        venezolano: {
          title: "¿Eres venezolano?",
          sub_title:
            "Si tu respuesta es positiva, por favor haznos llegar esta información adicional",
        },
        cash_in: "¿Cómo quiere devolver?",
        ultima_direccion_venezuela: "Última dirección en Venezuela",
        ultimo_trabajo_venezuela: "Último trabajo en Venezuela",
        fuente_ingreso_principal: "Fuente de ingreso principal",
        referenciado_por: "Referido por",
      },
      property_type: "Tipo de vivienda",
      BNF: {
        datos_laborales: "Datos Laborales",
        empresa_trabajo: "Empresa donde trabaja",
        direccion: "Dirección empresa",
        ingresos: "Ingresos",
        sueldo: "Sueldo",
        honorarios: "Honorarios",
        ingreso_conyugue: "Ingreso Conyugue",
        gastos: "Gastos",
        gastos_familiares: "Familiares",
        gastos_tarjeta_credito: "Cuota tarjeta de credito",
        gastos_cuota_prestamo: "Cuotas de prestamo",
        gastos_otros: "Otros",
        empleado: "Empleado",
        jubilado: "Jubilado",
        propietario: "Propietario",
        tipo_trabajo: "Tipo de vínculo laboral",
        profesion: "Profesión",
        cargo_ocupa: "Cargo",
        ingresos_otros: "Otros",
        datos_conyugue: "Datos del conyugue",
        nombre_conyuge: "Nombre y Apellido",
        datos_particulares: "Datos particulares",
      },
    },
    form3_persona: {
      UY: { title: "Datos Bancarios" },
      MX: { title: "Datos Bancarios" },
      SP: { title: "Datos Bancarios" },
    },
    validacion_sms_default: {
      title: "Ingrese el código de verificación",
      sub_title:
        "Le hemos enviado un código a su numero de teléfono. El mensaje puede demorar unos segundos en llegar, en cualquier caso puede solicitar reenviar el código",
      verification_code: "Código de verificación",
      resend_btn: "Reenviar código",
    },
    descuento_terceros_cheque: {
      title: "Datos del cheque",
      librador_type: "Tipo de librador:",
      doc_label: "Cheque",
      librador_doc_number: "Documento",
      librador_name: "Nombres",
      librador_surename: "Apellidos",
      librador_phone: "Teléfono",
      bank: "Banco",
      account_number: "Número de cuenta",
      number: "Número de cheque",
      serie: "Serie",
      document: "Copia del documento",
      document_drag: "Arrastre el archivo aquí o ",
      document_click: "click para subir",
      document_warning: "Asegúrese de que el documento sea legible",
      company_name: "Razón social",
      drag: "Arrastre el archivo aquí o ",
      click: "click para subir",
    },
    descuento_terceros_factura: {
      title: "Datos de la factura",
      doc_label: "Factura",
      librador_doc_number: "Documento",
      librador_name: "Nombres",
      librador_email: "E-mail",
      document: "Copia del documento",
      document_drag: "Arrastre el archivo aquí o ",
      document_click: "click para subir",
      document_warning: "Asegúrese de que el documento sea legible",
      librador_phone: "Teléfono",
      drag: "Arrastre el archivo aquí o ",
      click: "click para subir",
    },
    form_garante: {
      UY: {
        title: "Inserte los datos del garante",
        identity_doc: "Documento de identidad",
        address: "Dirección",
        civil_state: "Estado civil",
        fecha_ingreso: "¿Desde cuándo está trabajando?",
        ingreso_mensual: "¿Cuántos son tus ingresos mensuales?",
        phone: "Móvil",
        state: "Departamento",
        born_date: "Fecha de nacimiento",
        relacion_laboral: "Relación laboral",
        company_phone: "Teléfono laboral",
        error_applicant: "El garante no puede ser el mismo que el solicitante.",
      },
      MX: {
        title: "Inserte los datos del garante",
        identity_doc: "CURP",
        level_3: "Municipio",
        address: "Dirección y localidad",
        civil_state: "Estado civil",
        fecha_ingreso: "¿Desde cuándo está trabajando?",
        ingreso_mensual: "¿Cuántos son tus ingresos mensuales?",
        phone: "Móvil",
        state: "Estado",
        level_4: "Colonia",
        born_date: "Fecha de nacimiento",
        relacion_laboral: "Relación laboral",
        company_phone: "Teléfono laboral",
        error_applicant: "El garante no puede ser el mismo que el solicitante.",
      },
      SP: {
        title: "Inserte los datos del garante",
        identity_doc: "Documento de identidad",
        address: "Dirección",
        civil_state: "Estado civil",
        fecha_ingreso: "¿Desde cuándo está trabajando?",
        ingreso_mensual: "¿Cuántos son tus ingresos mensuales?",
        phone: "Móvil",
        state: "Provincia",
        born_date: "Fecha de nacimiento",
        relacion_laboral: "Relación laboral",
        company_phone: "Teléfono laboral",
        error_applicant: "El garante no puede ser el mismo que el solicitante.",
      },
    },
    select_products_all: {
      title: "Conozca las distintas opciones",
      products: "Seleccione el crédito ",
    },
    oferta_up_descuentos_default: {
      label_1: "Recibís",
      label_2: " tasa del ",
      btn_accept: "Acepto la oferta",
    },
    oferta_down_solicitud_default: {
      label_1: "Mismo monto a solicitar",
      label_2: "en",
      label_3: "cuotas de",
      label_4: "Misma cantidad de cuotas",
    },
    oferta_up_solicitud_default: {
      label_1: "en",
      label_2: "cuotas",
      label_3: "de",
      btn_accept: "Acepto la oferta",
    },
    ofertas_default: {
      title: "Ofertas de préstamos",
      sub_title: "Generadas a partir de los datos ingresados",
      other_options: "otras opciones",
    },
    oferta_solicitud_default: {
      offer_line:
        "{monto_oferta} en {plazo} cuota de {monto-cuota} {term_type}",
      offer_line_plural:
        "{monto_oferta} en {plazo} cuotas desde {monto-cuota} {term_type}",
    },
    oferta_solicitud_table: {
      amount_requested: "Importe solicitado ",
      plazo: "Plazo",
      plazo_cuotas: " |{cuotas} cuota {type}|{cuotas} cuotas {type} ",
      tasa: "TAE ",
      fecha_pago: "Primera fecha de pago",
      amount: "Monto de la cuota",
    },
  },
  views: {
    final: {
      greetings: "La Solicitud ha sido enviada con éxito.",
      sent_ok: "",
      data_received:
        "Estimado cliente, hemos recibido sus datos. Muy pronto recibirá novedades en el correo electrónico que nos dejó, recuerde que para retirar el crédito deberá presentar los siguientes documentos:",
      data_received_2: {
        1: "1. Cedula de Identidad",
        2: "2. Comprobante de Ingresos",
        3: "3. Factura de Servicios",
        4: "4. Certificado Tributario (Opcional)",
      },
      back_button: "Volver al inicio",
    },
      final_vehiculo: {
          greetings: "La Solicitud ha sido enviada con éxito.",
          sent_ok: "",
          data_received:
              "Estimado cliente, hemos recibido sus datos. Muy pronto recibirá novedades en el correo electrónico que nos dejó, recuerde que para retirar el crédito deberá presentar los siguientes documentos:",
          data_received_2: {
              1: "1. Cedula de Identidad",
              2: "2. Certificado de Trabajo",
              3: "3. Factura de Servicio",
              4: "4. Carta Oferta ",
              5: "5. Certificado de Cumplimiento Tributario (opcional) ",
          },
          back_button: "Volver al inicio",
      },
    info_garante: {
      title: "Aún podemos ayudarte",
      sub_title:
        "Agrega una garantía, garante o codeudor que cumpla con los siguientes requisitos:",
      btn_add_grante: "Agregar garante",
      sub_title2: "",
      sub_title_aux: "",
      requirements: requirements,
    },
    no_podemos_ayudar: {
      title:
        "Detectamos un inconveniente con tu solicitud. Queremos ayudarte. " +
        "Podés llamar al 413 9000 o pasar por una de nuestras sucursales para ver los detalles.",
      sub_title: "Recuerda que los requisitos son:",
      requirements: requirements,
      sub_title_aux: "",
      sub_title2: "",
    },
    not_available: {
      title: {
        no_services: "Servicio no disponible",
        con_solicitud: "Solicitud en curso",
      },
      no_services:
        "Lamentablemente en este momento no podemos ayudarte." +
        "Nuestros servicios no estan disponibles",
      con_solicitud:
        "La persona con este número de documento ya tiene un solicitud en curso",
    },
    producto_caducado: {
      title: "Producto no disponible",
      sub_title:
        "Lamentablemente el producto selecionado no se encuentra disponible. " +
        "Comunicate con la empresa para mas información.",
    },
    final_doc: {
      title: "¡La Solicitud ha sido enviada con éxito!",
      sub_title: "",
    },
    validar_telefono: {
      resend_code: "Su código ha sido reenviado",
    },
    garante: {
      alert: {
        message:
          "La Persona con número de documento: {document} no es un garante válido, debe proporcionar otra Persona.",
        title: "Garante inválido",
        btn_aceptar: "Entendido",
        btn_cancelar: "Ver Requisitos",
      },
    },
    documentacion: {
      vale: {
        banner: "Contrato",
        not_reset_success: "El código le fue enviado nuevamente",
        not_reset_error: "Error al intentar reenviar código",
        not_route_obtain:
          "No se obtuvo una ruta válida de la documentación a firmar, por favor revise el código proporcionado y/o comúniquese con nosotros",
        already_signed: "El contrato de esta solicitud ya ha sido firmado",
      },
    },
    pagos: {
      banner: "Autorización de Pago",
      title: "Autorización de Pago",
      sub_title:
        "Reddo  ayuda a mantener al día  tus pagos.  Domicilia  las cuotas mensuales de tu crédito. Haz click aquí:",
      prestamo: "#Prestamo: ",
      monto_prestamo: "Monto Prestamo: ",
      cant_cuotas: "Cantidad de Cuotas: ",
      monto_cuotas: "Monto de Cuotas: ",
      fechas_vencimiento: "Fechas de Cobro de cuota ",
      btn_accept: "Aceptar Pago",
      not_btn_accept: "No Aceptar",
      already_signed: "El pago de esta solicitud ya ha sido procesada",
      not_route_obtain:
        "No se puede generar el cobro de la cuota,por favor  comúniquese con nosotros",
      not_route_obtain_naat:
        "No se puede generar el cobro de la cuota, por favor comúniquese con nosotros",
      ya_procesado: "Pago recurrente ya procesado",
      data_received:
        "Para realizar los pagos mensuales por transferencia, de las cuotas de tu crédito Reddo, sigue las instrucciones: ",
      final_sub_title: "",
      final_title: "Final proceso!",
      cuota: "Cuota # ",
    },
  },
  commons: {
    phone_number: {
      label: "Número de teléfono",
    },
    address: {
      label: "Dirección",
      postal_code: "Código postal",
      select_previous: "Seleccione {name} primero",
    },
    open_banking: {
      title_header: "Open Banking",
      label: "Quieres que obtengamos los datos de tu cuenta bancaria",
      text:
        "Este es un servicio facilitado por {instanor_link}  para {company} " +
        "con el objeto de verificar tu capacidad para hacer frente a los pagos." +
        " Sólo necesitas iniciar sesión con los mismos datos con los que accedes a tus " +
        "servicios de banca online, e {instanor_link} realizará la comprobación de forma segura " +
        "y confidencial en cuestión de segundos. Tras la aceptación del préstamo, te transferiremos " +
        "la cantidad aprobada en minutos.{info_extra}",
      not_keep_pasword: " No guardamos usuarios ni contraseñas.",
      statements: {
        acces_data:
          "Tus datos de acceso no serán almacenados ni vistos por nadie",
        service: "Servicio proporcionado por Instantor AB",
        save: "Seguro y fiable",
        fast: "Rápido - Respuesta en minutos",
        easy: "Fácil - Sólo tienes que iniciar sesión en tu banco",
      },
      title: "Estás en buenas manos",
    },
    bank_aggregation: {
      title_header: "Agregación Bancaria Unnax",
    },
    youSing: {
      title: "Ingrese documento",
      sub_title:
        "Entre el documento de identidad con el que realizó la solicitud",
    },
    docuSing: {
      title: "Ingrese el código y  documento",
      sub_title:
        'Si desea que le reenvien el código, deje el campo del código vacio y presione "{reenviar}"',
    },
  },
  title: {
    "pz-products-all-select": "Seleccione el producto que más le convenga",
    //**simuladores
    "pz-slider-simulator": "Calcula tu crédito",
    "pz-data-input-simulator": "Calcula tu crédito",
    "pz-vehiculo-simulator": "Calcula tu crédito",
    //**simuladores
    //**Form1
    "pz-person-form-es1": "Solicitud de préstamo",
    "pz-person-form-es2": "Solicitud de préstamo",
    "pz-person-form-es3": "Datos Bancarios",
    "pz-company-form1-uy": "Datos del solicitante",
    "pz-person-form1-default": "Datos del solicitante",
    "pz-person-form1-uy": "Datos del solicitante",
    "pz-person-form1-vehiculo": "Datos del solicitante",
    "pz-person-form-1-mx": "Solicitud de préstamo",
    "pz-company-form-1-mx": "Solicitud de préstamo",
    //**Form1
    //**Form2
    "pz-person-form-ext-es": "Información del solicitante",
    "pz-company-form-ext-es": "Información del solicitante",
    "pz-company-form-ext-uy": "Información del solicitante",
    "pz-person-form-ext-uy": "Información del solicitante",
    "pz-person-form-ext-mx": "Información del solicitante",
    "pz-company-form-ext-mx": "Información del solicitante",
    //**Form1
    //**Oferta
    "pz-offers-uy": "Oferta",
    //**Oferta
    //**Doc
    "pz-company-documentacion-uy": "Documentación",
    "pz-person-documentacion-uy": "Documentación",
    //**Desc
    "pz-cheque-descuento-terceros": "Documentación de terceros",
    "pz-factura-descuento-terceros": "Documentación de terceros",
    //**Desc
    //**Validación telefono
    "pz-validate-by-sms": "Validar teléfono",
    //**Validación telefono
    //**Garante
    "pz-garante-form-uy": "",
    "pz-garante-form-mx": "",
    //**Garante
    "pz-message-uy": "Felicitaciones",
     "pz-message-es": "Felicitaciones",
    "pz-message-vehiculo": "Felicitaciones",
    "pz-person-form-ext-bnf": "Información del solicitante",
    "pz-person-form-ext-vehiculo": "Información del solicitante",
    "pz-person-form-ext2-bnf": "Información del solicitante",
    "pz-person-form-ext2-vehiculo": "Información del solicitante",
    "pz-person-form1-bnf": "Datos del solicitante",
    "pz-firma-docs": "Firma de Documentos",
  },
};
export default keys;
